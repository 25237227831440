.murious_events{
    background: linear-gradient(102.67deg, #129106 33.14%, #5EBF4E 100%);
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 3rem;
    border-radius: 10px;
}
.events_head{
    font-weight: 600;
    font-size: 111px;
    color: white;
    text-align: center;
    padding-bottom: 3rem;
}

@media screen and (max-width: 400px){
    .events_head{
        font-size: 60px;
        margin-bottom: -1rem;
    }
    .murious_events{
        padding-top: 3rem;
        height: 30rem;
    }
}
.sc-card{
    background: linear-gradient(86.36deg, #129106 0.81%, #5EBF4E 95.27%);
    border-radius: 10px;
    width: 75rem;
    height: 5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sc-time{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 30px;
    margin-left: 2rem;
}
.sc-ename{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 42px;
    width: 60%;
}
@media screen and (max-width: 400px) {
    .sc-card{
        width: 100%;
        height: auto;
    }
    .sc-time{
        margin-left: 1rem;
        font-size: 12px;
    }
    .sc-ename{
        font-size: 14px;
        width: 50%;
    }
}
.te-landing{
    background: url("./Assets/home.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.te-heading{
    font-family: alpha;
    color: white;
    font-weight: 500;
    font-size: 123.447px;
    padding-bottom: 2rem;
    margin-top: 5rem;
}
.te-heading span{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 127px;
}
.te-landing-register{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    background: none;
    padding: 0px 16px 5px 16px;
    color: white;
    text-decoration: none;
    border: 3px solid white;
}
.te-landing-register:hover{
    background-color: white;
    color: black;
    border-color: white;
}

@media screen and (max-width: 400px) {
    .te-heading span{
        font-size: 40px;
    }
    .te-heading{
        font-size: 40px;
    }
    .te-landing{
        height: 41.2rem;
    }
}
.timer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  padding: 60px 0px;
  text-align: center;
  background: linear-gradient(86.5deg, #129106 32.7%, #5ebf4e 95.44%);
  width: 100%;
}
.col-4 {
  width: 25%;
  float: left;
}
.box {
  padding: 10px;
}

.col-4:last-child .box {
  border-right-color: transparent;
}

.box p {
  font-size: calc(16px + 2vmin);
  margin: 0;
}

#day,
#hour,
#minute,
#second,
.text {
  font-weight: 500;
  font-size: 64px;
  color: #ffffff;
}
.text {
  margin-left: 1.5rem;
}

@media screen and (max-width: 400px) {
  #day,
  #hour,
  #minute,
  #second,
  .text {
    font-size: 20px;
  }
  .text{
    margin-left: 0.3rem;
  }
  .timer{
    padding: 25px 0;
  }
}
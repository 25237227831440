.sc-expsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1f1f1f;
    padding: 4rem 0;
    position: relative;
}
.sc-expanse{
    z-index: 1;
    color: white;
    font-weight: 500;
    font-size: 111px;
    padding-bottom: 3rem;
}
.sc-tcards{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 1;
}
.orn-5{
    position: absolute;
    left: 0;
}
.orn-6{
    right: 0;
    top: 12.4rem;
    position: absolute;
}
.orn-7{
    left: 0;
    bottom: 6.9rem;
    position: absolute;
}
.orn-8{
    right: 0;
    bottom: -9rem;
    position: absolute;
}

@media screen and (max-width: 400px) {
    .sc-expanse{
        font-size: 52px;
    }
    .orn-5{
        width: 8rem;
    }
    .orn-6{
        width: 9rem;
        top: 8rem;
    }
    .orn-7{
        width: 9rem;
    }
    .orn-8{
        width: 6rem;
        margin-bottom: 7rem;
    }
}
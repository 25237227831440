.events_car{
    width: 100%;
    height: 5rem;
}
.evcar_slides{
    display: flex;
    flex-direction: column;
    position: relative;
}
.events_car_head{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 58px;
    color: white;
}
.events_car_desc{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 26px;
    color:white;
    text-align: justify;
    width: 80%;
}
.slideInner___2mfX9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-left: 10rem;
    gap: 2rem;
}
.event_regb{
    font-weight: 300;
    font-size: 34px;
    color: white;
    text-decoration: none;
    border: 3px solid #FFFFFF;
    padding: 0 25px;
}
.event_regb:hover{
    background-color: #fff;
    color: black;
}
.but_back{
    position: absolute;
    top: 18rem;   
    left: 5rem;
    background: none;
    border: none;
}
.but_next{
    position: absolute;
    top: 18rem;
    right: 5rem;
    background: none;
    border: none;
}
.but_back img{
    width: 2rem;
    background: none;
}
.but_next img{
    width: 2rem;
    background: none;
}

@media screen and (max-width: 400px){
    .events_car_head{
        font-size: 30px;
    }
    .events_car_desc{
        font-size: 16px;
        width: 75%;
    }
    .slideInner___2mfX9{
        margin-left: 0;
        width: 100%;
    }
    .but_back img{
        width: 1rem;
    }
    .but_next img{
        width: 1rem;
    }
    .but_back{
        top: 20.2rem;
        left: 0.5rem;
    }
    .but_next{
        top: 20.2rem;
        right: 0.5rem;
    }
}